<template>
  <v-menu
    left
    bottom
    min-width="500px"
    max-width="35%"
    max-height="70%"
    offset-y
    transition="slide-y-transition"
    :close-on-content-click="false"
  >
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn v-on="{ ...onMenu, ...onTooltip }" @click.stop="" fab x-small depressed>
            <v-badge color="secondary" overlap :content="unread.length" :value="unread.length">
              <img
                alt="notifications"
                height="18"
                width="18"
                src="../../../assets/icons/notifications.svg"
              />
            </v-badge>
          </v-btn>
        </template>
        <span>Notificaciones</span>
      </v-tooltip>
    </template>

    <v-sheet>
      <div class="d-flex align-center justify-space-between">
        <v-subheader class="text-h6">Notificaciones</v-subheader>
        <div>
          <v-btn v-if="unread.length > 0" @click="readAll" text plain x-small>
            Marcar todas como leidas
          </v-btn>
          <v-btn
            v-else-if="this.notifications.length > 0"
            @click="archiveAll"
            color="error"
            text
            plain
            x-small
          >
            Limpiar notificaciones
          </v-btn>
        </div>
      </div>
      <v-divider></v-divider>

      <!-- Error panel -->
      <v-sheet
        v-if="this.errorSync"
        class="text-center pa-3 font-weight-bold"
        color="red lighten-2"
        dark
      >
        <p>
          Hay un error en la sincronización. Si el error persiste, contacta con el administrador
        </p>
        <div>
          <v-btn @click="getNewNotifications()" outlined small>
            <v-icon size="18" class="mr-2">refresh</v-icon>
            Sincroniza
          </v-btn>
        </div>
      </v-sheet>

      <!-- Notifications list -->
      <v-list v-if="this.notifications.length > 0">
        <v-list-item
          v-for="(item, index) in notifications"
          :key="item.id"
          :class="{
            'prd-notification-list--divider': index + 1 < notifications.length,
            dark: $store.state.userSettings.darkMode,
          }"
          class="prd-notification-list"
        >
          <v-list-item-content>
            <div class="d-flex justify-space-between">
              <span class="subtitle-2" v-text="item.title" />
              <span class="d-flex align-center text-caption text--disabled">
                <span class="mr-2">{{ item.date }}</span>
                <v-btn
                  x-small
                  icon
                  color="error"
                  class="prd-notification-list--delete-icon ml-2"
                  @click="archiveNotification(item.id)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </span>
            </div>
            <div class="d-flex justify-space-between align-center">
              <span
                class="body-2 mr-2"
                :class="{ 'font-weight-bold': !item.viewed }"
                v-html="item.description"
              />

              <!--Unread icon-->
              <div>
                <v-btn
                  v-if="!item.viewed"
                  @click="readNotification(item.id)"
                  x-small
                  icon
                  color="primary"
                >
                  <v-icon>fiber_manual_record</v-icon>
                </v-btn>

                <!--Featured Icon-->
                <v-icon v-if="item.featured" color="warning">star</v-icon>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-else class="py-8 d-flex align-center justify-center">No hay notificaciones</div>
    </v-sheet>
  </v-menu>
</template>

<script>
import ServiceNotifications from "@/services/NotificationService";

export default {
  data: () => ({
    notifications: [],
    newNotifications: [],
    lastTimeRefreshed: null,
    intervalSeconds: 10,
    errorSync: false,
  }),
  methods: {
    readNotification(itemId) {
      const params = {
        id: [itemId],
      };
      ServiceNotifications.readNotifications(params)
        .then(() => {
          const notification = this.notifications.find(n => n.id === itemId);
          notification.viewed = true;
        })
        .catch(() => {
          this.$notifyError();
        });
    },
    archiveNotification(itemId) {
      const params = {
        id: [itemId],
      };
      ServiceNotifications.archiveNotifications(params)
        .then(() => {
          const notificationIndex = this.notifications.findIndex(n => n.id === itemId);
          this.notifications.splice(notificationIndex, 1);
        })
        .catch(() => {
          this.$notifyError();
        });
    },
    readAll() {
      const unreadNotifications = this.notifications.filter(n => !n.viewed);
      const params = {
        id: unreadNotifications.map(n => n.id),
      };
      // all read backend
      ServiceNotifications.readNotifications(params)
        .then(() => {
          // if OK, all read frontend
          for (const not of unreadNotifications) {
            not.viewed = true;
          }
        })
        .catch(() => {
          this.$notifyError();
        });
    },
    archiveAll() {
      const params = {
        id: this.notifications.map(n => n.id),
      };
      // archive all backend
      ServiceNotifications.archiveNotifications(params)
        .then(() => {
          // if OK, clean notifications
          this.notifications = [];
        })
        .catch(() => {
          this.$notifyError();
        });
    },
    getNewNotifications() {
      ServiceNotifications.getNewNotifications()
        .then(response => {
          this.newNotifications = response.data;
        })
        .then(() => {
          this.notifications = this.newNotifications.concat(this.notifications);

          if (this.errorSync) {
            this.errorSync = false;
          }
          this.lastTimeRefreshed = this.getNowInSeconds();
        })
        .catch(() => {
          this.errorSync = true;
        });
    },
    getNowInSeconds() {
      return Math.floor(Date.now() / 1000);
    },
  },
  computed: {
    unread() {
      return this.notifications.filter(n => !n.viewed);
    },
  },
  mounted() {
    ServiceNotifications.getNotifications().then(response => {
      this.notifications = response.data;
    });
  },
  watch: {
    $route: {
      handler() {
        const pastInterval =
          this.getNowInSeconds() - this.lastTimeRefreshed > this.intervalSeconds || false;

        if (pastInterval) {
          // get New notifications on route change, only
          // if it's the first time or have past this.interval since last refresh,
          // then, get New Notifications and merge them with this.notifications
          this.getNewNotifications();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.prd-notification-list {
  &--divider {
    border-bottom: 1px solid lightgray;
  }
  &--delete-icon {
    display: none;
  }
  &:hover {
    background-color: #f5f5f5;
    .prd-notification-list--delete-icon {
      display: unset;
    }
  }
  &.dark:hover {
    background-color: #151515;
  }
}
</style>
