var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"left":"","bottom":"","min-width":"500px","max-width":"35%","max-height":"70%","offset-y":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","depressed":""},on:{"click":function($event){$event.stopPropagation();}}},Object.assign({}, onMenu, onTooltip)),[_c('v-badge',{attrs:{"color":"secondary","overlap":"","content":_vm.unread.length,"value":_vm.unread.length}},[_c('img',{attrs:{"alt":"notifications","height":"18","width":"18","src":require("../../../assets/icons/notifications.svg")}})])],1)]}}],null,true)},[_c('span',[_vm._v("Notificaciones")])])]}}])},[_c('v-sheet',[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-subheader',{staticClass:"text-h6"},[_vm._v("Notificaciones")]),_c('div',[(_vm.unread.length > 0)?_c('v-btn',{attrs:{"text":"","plain":"","x-small":""},on:{"click":_vm.readAll}},[_vm._v(" Marcar todas como leidas ")]):(this.notifications.length > 0)?_c('v-btn',{attrs:{"color":"error","text":"","plain":"","x-small":""},on:{"click":_vm.archiveAll}},[_vm._v(" Limpiar notificaciones ")]):_vm._e()],1)],1),_c('v-divider'),(this.errorSync)?_c('v-sheet',{staticClass:"text-center pa-3 font-weight-bold",attrs:{"color":"red lighten-2","dark":""}},[_c('p',[_vm._v(" Hay un error en la sincronización. Si el error persiste, contacta con el administrador ")]),_c('div',[_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.getNewNotifications()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v("refresh")]),_vm._v(" Sincroniza ")],1)],1)]):_vm._e(),(this.notifications.length > 0)?_c('v-list',_vm._l((_vm.notifications),function(item,index){return _c('v-list-item',{key:item.id,staticClass:"prd-notification-list",class:{
          'prd-notification-list--divider': index + 1 < _vm.notifications.length,
          dark: _vm.$store.state.userSettings.darkMode,
        }},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"subtitle-2",domProps:{"textContent":_vm._s(item.title)}}),_c('span',{staticClass:"d-flex align-center text-caption text--disabled"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.date))]),_c('v-btn',{staticClass:"prd-notification-list--delete-icon ml-2",attrs:{"x-small":"","icon":"","color":"error"},on:{"click":function($event){return _vm.archiveNotification(item.id)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"body-2 mr-2",class:{ 'font-weight-bold': !item.viewed },domProps:{"innerHTML":_vm._s(item.description)}}),_c('div',[(!item.viewed)?_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.readNotification(item.id)}}},[_c('v-icon',[_vm._v("fiber_manual_record")])],1):_vm._e(),(item.featured)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("star")]):_vm._e()],1)])])],1)}),1):_c('div',{staticClass:"py-8 d-flex align-center justify-center"},[_vm._v("No hay notificaciones")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }